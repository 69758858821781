import React from "react"
import styled from "styled-components"

const ListColorBorad = styled.ul`	
	list-style:none;
	margin:0 -5px;
	padding:0;
`
const ColorItem = styled.li`	
	display: inline-block;
	padding:0 5px;
	margin-bottom: 15px;
`
const ColorCode= styled.span`	
	display:block;
	width: 90px;
	height: 40px;
	background: ${props=>props.bg};	
	margin-bottom:5px;	
	border:1px solid #ddd;
`
const ColorName= styled.span`	
	display:block;	
	font-size:12px;
	color:#666;
	font-weight:500;
	text-align:center;
	line-height:20px;
`

const ColorBoard = () => {
	return(	
		<ListColorBorad>
			<ColorItem>
				<ColorCode bg="#345B6E"></ColorCode>								
				<ColorName>Slate Blue</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#935B44"></ColorCode>								
				<ColorName>Copper</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#1E3C23"></ColorCode>								
				<ColorName>Evergreen</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#073D2B"></ColorCode>								
				<ColorName>MC Green</ColorName>
			</ColorItem>
			
			<ColorItem>
				<ColorCode bg="#742429"></ColorCode>								
				<ColorName>Barn Red</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#000000"></ColorCode>								
				<ColorName>Black</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#33161E"></ColorCode>								
				<ColorName>Burgundy</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#979897"></ColorCode>								
				<ColorName>Galvalume</ColorName>
			</ColorItem>
			
			<ColorItem>
				<ColorCode bg="#B7B7B7"></ColorCode>								
				<ColorName>Clay</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#9E9E9E"></ColorCode>								
				<ColorName>MC Gray</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#585858"></ColorCode>								
				<ColorName>Quaker Gray</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#888888"></ColorCode>								
				<ColorName>Pewter Gray</ColorName>
			</ColorItem>

			<ColorItem>
				<ColorCode bg="#AC9179"></ColorCode>								
				<ColorName>Tan</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#4B2D27"></ColorCode>								
				<ColorName>Brown</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#D2CEBF"></ColorCode>								
				<ColorName>Sandstone</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#EFE2D6"></ColorCode>								
				<ColorName>Beige</ColorName>
			</ColorItem>
			<ColorItem>
				<ColorCode bg="#ffffff"></ColorCode>								
				<ColorName>White</ColorName>
			</ColorItem>							
		</ListColorBorad>					
	)
}


export default ColorBoard